<template>
    <div id="peopleCensus">
        <div
            class="filter-panel"
            style=
                "
                    display:flex;
                    align-items:center;
                    justify-content:flex-start;
                "
        >
            <CSSelect style="width:210px">
                <el-date-picker
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择开始时间"
                    v-model="searchTerm.startTime"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
            </CSSelect>
            <span
                style="
                display: inline-block;
                vertical-align:middle;
                margin:0px 20px 15px 0px;
                height: 2px;
                width:20px;
                background-color:#999;
                "
            ></span>
            <CSSelect style="width:210px">
                <el-date-picker
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择结束时间"
                    v-model="searchTerm.endTime"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
            </CSSelect>
            <div style="display: inline-block; margin-right: 30px;">
              <TreePanelSelect :default-select="checkedCameraName" @loadMore="loadCameraList" :traverse-data="cameraOptions" text-name="name" @onClick="changeCheckedCamera"></TreePanelSelect>
            </div>
            <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="getCensusData()"
            >
                查询
            </button>
        </div>
        <div class="result-panel" :style="'height:'+(echartHeight - 240)+'px'">

        </div>
    </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import ChooseLocation from "@/components/ChooseLocation";
import {queryCameraListFilterUrl, queryCameraListUrl, queryCameraPeopleCountUrl} from "@/requestUrl";
import TreePanelSelect from "@/components/common/TreePanelSelect";
export default {
    components:{
      TreePanelSelect,
        CSSelect,
        ChooseLocation,
    },
    data(){
        return {
            checkedCameraName: '',
            searchTerm:{
                startTime: dayjs().subtract(7,'days').format("YYYY-MM-DD"),
                endTime: dayjs().format("YYYY-MM-DD"),
                id: "",
            },
            identify:"camera",
            echartHeight:621,
            cameraOptions: [],
        }
    },
    async created(){
        await this.loadCameraList();
        this.echartHeight = document.documentElement.clientHeight;
        if (this.searchTerm.id) {
            this.getCensusData();
        }
    },
    methods:{
        /**
         * 修改选中的监控
         * @param {Number} id 监控id
         * */
        changeCheckedCamera(id) {
          this.searchTerm.id = id;
          this.checkedCameraName = '';
        },
        /**
         * 加载监控列表
         * @param {Number} pageNo
         * @param {Number} pageSize
         * */
        loadCameraList(pageNo = 1, pageSize = 10) {
          return this.$fly.post(queryCameraListFilterUrl, {
            search: '',
            isPeopleCounting: 1,
            cameraGroupId: '',
            ids: [],
            pageNo,         //页数
            pageSize,       //页面大小
          })
          .then(async res => {
            if (res.code !== 0) {
              return;
            }
            if (res?.data?.list && res.data.list.length > 0) {
                this.searchTerm.id = res.data.list[0].id;
                this.checkedCameraName = res.data.list[0].name;
                this.cameraOptions.push(...res.data.list);
                return res.data.list;
            }
            return [];
          })
        },
        getCensusData(){
          this.$fly.post(queryCameraPeopleCountUrl, this.searchTerm)
          .then(res => {
            if (res.code !== 0) {
              return ;
            }
            this.$nextTick(() => {
              const  myChart = this.$echarts.init(document.querySelector("#peopleCensus .result-panel"));
              const option = {
                color:["#1ab394"],
                xAxis:{
                  axisTick:{
                    show:false
                  },
                  axisLine: {
                    show: false
                  },
                  data: res.data.map(item => item.time),
                },
                yAxis:{
                  max: 50,
                  min: 0,
                  axisTick:{
                    show:false
                  },
                  axisLine: {
                    show: false
                  },

                  minInterval: 1,

                },
                series:[
                  {
                    type:'bar',
                    data: res.data.map(item => item.count),
                    label: {
                      show: true,
                      position: 'inside'
                    },
                  },

                ]
              }
              myChart.setOption(option)
            })
          })
        }
    }
}
</script>

<style>

</style>
